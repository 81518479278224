import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration, ExtraErrorData } from '@sentry/integrations';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

if (process.env.NODE_ENV === 'production' && window && window.env === 'live') {
  Sentry.init({
    environment: 'prod',
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
      }),
      new ExtraErrorData({
        depth: 8,
      }),
    ],
  });

  Sentry.setTag('prod-version', 2);

  // window.user gets set in metatags.php
  if (window.user !== undefined) {
    Sentry.setUser(window.user);
  }

  // Add custom session ID for easier searching
  // even if the user has not been authenticated yet
  // and to provide information if errors occured in the same session
  Sentry.setTag('session', uuidv4());
}
